<template>
  <div class="dinput-checkbox">
    <sl-checkbox v-model="response.value" />
  </div>
</template>

<script>
import Mixin from "@/components/forms/input/dynamic/inputs/Mixin"
import SlCheckbox from "@/reuse/input/Checkbox"

export default {
  name: "DCheckbox",
  components: {SlCheckbox},
  mixins: [Mixin]
}
</script>
