<template>
  <div class="itemAba wrapper-md" :class="{'is-loading': loading}" v-if="loading">
    Carregando formulário, por favor, aguarde...<!-- <a @click="loadForm">Tentar novamente</a>-->
  </div>
  <div class="itemAba wrapper-md" v-else>
    <div class="app-forms-container box-contato-simple m-t-n wrapper e-input-modern fin-form simple-btn" v-for="form in forms" :key="form.id">
      <mount-form hide-name :form="form" :entity-name="entityName" :entity-id="entityId" />
    </div>
  </div>
</template>

<script>
import AppMixin from "@/components/pessoa/components/include/pessoa/apps/appMixin"
import {getForm} from "@/domain/form/services"
import MountForm from "@/components/forms/components/mount/Form"

export default {
  name: "ProcessoChecklist",
  components: {
    MountForm
  },
  props: ['processo'],
  data () {
    return {
      entityName: 'processo-checklist',
      entityId: this.processo.id,
      forms: null
    }
  },
  mixins: [AppMixin],
  mounted() {
    this.loadForm()
  },
  methods: {
    loadForm () {
      this.loading = true
      getForm(null, this.entityName, null, null, this.processo.id)
          .then(response => {
            console.log(response)
            this.forms = response.data.forms
            this.loading = false
          })
          .catch(error => {
            this.alertApiError(error)
          })
    }
  }
}
</script>

