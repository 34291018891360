<template>
  <div class="app-form" :class="cssLayout">
    <div class="form-name" v-if="!hideName">{{form.name}}</div>
    <div class="fields-without-topics">
      <fields-group :fields="getFieldsFromTopic(null)" />
    </div>
    <div class="form-topics">
      <div v-for="topic in form.topics" :key="'topic-' + topic.id">
        <div class="topic-title">{{topic.name}}</div>
        <fields-group :fields="getFieldsFromTopic(topic.id)" />
      </div>
    </div>
    <div class="form-footer">
      <u-btn label="Salvar" class="sl-btn" color="positive" no-caps @click="save" :loading="saving" />
    </div>
  </div>
</template>

<script>
import FieldsGroup from "@/components/forms/components/mount/FieldsGroup"
import {saveForm} from "@/domain/form/services"
export default {
  name: "MountForm",
  props: {
    form: {
      type: Object,
      required: true
    },
    entityName: {
      type: String,
      required: true
    },
    entityId: {
      type: String,
      required: true
    },
    hideName: {
      type: Boolean,
      default: false
    },
    layout: {
      type: String,
      default: 'default'
    }
  },
  data () {
    return {
      saving: false
    }
  },
  computed: {
    cssLayout () {
      const css = []
      css.push('layout-' + this.layout)
      return css
    }
  },
  methods: {
    getFieldsFromTopic (topic = null) {
      return this.form.fields.filter(f => {
        if (!topic) {
          return f.topic === null
        }
        return f.topic && f.topic.id === topic
      })
    },
    save () {
      this.saving = true
      saveForm({
        form: this.form,
        entityName: this.entityName,
        entityId: this.entityId,
      })
          .then(response => {
            this.saving = false
            this.dg()
          })
          .catch(error => {
            this.saving = false
            this.alertApiError(error)
          })
    }
  },
  components: {
    FieldsGroup
  }
}
</script>
